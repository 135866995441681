import { Link } from 'gatsby';
import React from 'react';
import YoutubeIcon from '../../icons/youtube.svg';

export enum ResourceType {
  BLOG,
  YOUTUBE,
  LINK,
}

interface ResourceCardProps {
  title: string;
  link: string;
  description: string;
  type: ResourceType;
  linkText?: string;
}

const ResourceCard = (props: ResourceCardProps) => {
  const getResourceLink = () => {
    switch (props.type) {
      case ResourceType.BLOG:
        return (
          <Link
            to={props.link}
            className="ml-2 text-foreground underline underline-offset-2"
          >
            {props.linkText || 'Know More >'}
          </Link>
        );
      case ResourceType.YOUTUBE:
        return (
          <a
            href={props.link}
            target="__blank"
            referrerPolicy="no-referrer"
            className="ml-2 text-foreground underline underline-offset-2"
          >
            Learn More
            <span>
              <YoutubeIcon className="h-[1.2rem] w-[auto] inline ml-2" />
            </span>
          </a>
        );
      case ResourceType.LINK:
        return (
          <a
            href={props.link}
            target="__blank"
            referrerPolicy="no-referrer"
            className="ml-2 text-foreground underline underline-offset-2"
          >
            Explore &gt;
          </a>
        );
    }
  };
  return (
    <div className="card">
      <h3 className="font-bold text-foreground text-xl">{props.title}</h3>
      <p>
        {props.description}
        <span>{getResourceLink()}</span>
      </p>
    </div>
  );
};

export default ResourceCard;
