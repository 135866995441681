import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import ResourceCard, { ResourceType } from '../components/ResourceCard';

const allResourcesQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          excerpt
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`;

const Resources = () => {
  const data = useStaticQuery(allResourcesQuery);

  return (
    <Layout>
      <section className="my-6 section h-[100%]">
        <div>
          <div>
            <h2 className="text-center">Resources</h2>
          </div>
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 my-6 justify-center auto-rows-[1fr]">
            {data.allMdx.edges.map((edge: any) => (
              <div key={edge.node.slug}>
                <ResourceCard
                  title={edge.node.frontmatter.title}
                  description={edge.node.excerpt}
                  link={`/${edge.node.frontmatter.slug}`}
                  type={ResourceType.BLOG}
                />
              </div>
            ))}
            <ResourceCard
              title="Bitnimbus.io Affordable Pricing"
              description="Discover how Bitnimbus' Apache Kafka solution can help you save thousands compared to other providers like Instaclustr and Aiven."
              link="https://www.youtube.com/watch?v=V67mCZ1E0NY"
              type={ResourceType.YOUTUBE}
            />
            <ResourceCard
              title="Getting Started with Bitnimbus.io Kafka"
              description="Deploying Kafka in Bitnimbus.io platform is a simple task which
                can be done in few clicks. Here are the things to deploy your
                Kafka cluster."
              link="https://www.youtube.com/watch?v=-1XHYLzkpqU"
              type={ResourceType.YOUTUBE}
            />
            <ResourceCard
              title="Kafka UI Integration"
              description="Kafka act as a medium for your services to communicate with each other. Let's integrate Provectus lab's KafkaUI to connect, product and consume messages"
              link="https://www.youtube.com/watch?v=M0OpWwTN13M"
              type={ResourceType.YOUTUBE}
            />
            <ResourceCard
              title="Bitnimbus.io Docs"
              description=" We have a documentation site that contains all the contents related to our products."
              link="https://docs.bitnimbus.io"
              type={ResourceType.LINK}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Resources;
